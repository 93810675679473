@import 'node_modules/@own/fds-react/dist/main.min';

// Override FDS Styles for these core elements
h1,
h2,
h3,
h4,
h5,
h6,
body,
p {
    color: unset;
    font-size: revert;
    line-height: revert;
    letter-spacing: normal;
}

body {
    &.open {
        overflow: hidden;
    }
}

li,
a {
    letter-spacing: normal;
}

a,
a:link,
a:active {
    color: revert;
    &:focus {
        outline: revert;
        outline-offset: 0;
    }
}

p,
div,
button,
input,
input:active,
select {
    &:focus {
        outline: revert;
        outline-offset: 0;
    }
}
